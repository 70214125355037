import React from 'react';
import styles from './../../css/HistoryItem.module.css';

function HistoryItem({ year, title, description, subtitle, subdescription, backgroundColor, isHighlighted }) {
  const textColor = isHighlighted ? '#fff' : '#000'; // White for the second item, black for others

  return (
    <article className={styles.historyItem} style={{ backgroundColor }}>
      <div className={styles.content}>
        <h2 className={styles.year} style={{ color: textColor }}>{year}</h2>
        <div className={styles.details}>
          <h3 className={styles.title} style={{ color: textColor }}>{title}</h3>
          <p className={styles.description} style={{ color: textColor }}>{description}</p>
          {subtitle && <h4 className={styles.subtitle} style={{ color: textColor }}>{subtitle}</h4>}
          {subdescription && <p className={styles.subdescription} style={{ color: textColor }}>{subdescription}</p>}
        </div>
      </div>
    </article>
  );
}

export default HistoryItem;
