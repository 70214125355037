import React from 'react';
import styles from './../../css/ContactSection.module.css';

function ContactSection() {
  return (
    <section className={styles.contactSection}>
      <h2 className={styles.title}>Stojimo Vam na usluzi!</h2>
      <p className={styles.description}>
        Za sva dodatna pitanja i nedoumice, slobodno nas kontaktirajte. Naš stručni tim je tu da vam pruži sve potrebne informacije i vodi vas kroz proces. Čekamo vaš poziv s nestrpljenjem!
      </p>
      <button className={styles.contactButton}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/63401b1fbf0be85708bdfc756a2866ea114399b02e32d46cf00ef3afe7fd76eb?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" className={styles.buttonIcon} />
        <span className={styles.buttonText}>Kontaktirajte nas</span>
      </button>
    </section>
  );
}

export default ContactSection;