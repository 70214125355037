import React from 'react';
import styles from './../../css/Registration.module.css';

function CallToAction() {
  return (
    <section className={styles.cta}>
      <div className={styles.ctaContent}>
        <h2 className={styles.ctaTitle}>Stojimo Vam na usluzi!</h2>
        <p className={styles.ctaDescription}>
          Za sve upite i nejasnoće oko osiguranja drona, registracije operatora i osposobljavanja udaljenih pilota slobodno nam se javite.
        </p>
        <button className={styles.ctaButton}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/63401b1fbf0be85708bdfc756a2866ea114399b02e32d46cf00ef3afe7fd76eb?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" className={styles.ctaButtonIcon} />
          <span>Kontaktirajte nas</span>
        </button>
      </div>
    </section>
  );
}

export default CallToAction;