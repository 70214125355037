import React from 'react';
import styles from './../../css/Registration.module.css';

function ServiceStep({ step, title, description, image, link }) {
  return (
    <div className={styles.serviceStep}>
      <img src={image} alt="" className={styles.stepImage} />
      <div className={styles.stepContent}>
        <div className={styles.stepHeader}>
          <span className={styles.stepNumber}>Korak {step}</span>
          <h3 className={styles.stepTitle}>{title}</h3>
        </div>
        <p className={styles.stepDescription}>{description}</p>
        <div className={styles.stepLink}>
          <span className={styles.linkLabel}>Linkovi</span>
          <a href={link.url} className={styles.link} target="_blank" rel="noopener noreferrer">
            <img src={link.icon} alt="" className={styles.linkIcon} />
            {link.text}
          </a>
        </div>
      </div>
    </div>
  );
}

export default ServiceStep;
