import * as React from 'react';
import logo from './../../img/LOGO_pozitiv_transparentback.png';
import document from './../../document/Upitnik za osiguranje bespilotnog zrakoplova.docx'
import {Link} from "react-router-dom";
import flag from "./../../img/united-kingdom-flag-icon.png"

function NavBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{ backgroundColor: "transparent", zIndex:"10" }}>
      <div className="container-fluid" style={{maxWidth: "1152px"}}>
        <a className="navbar-brand" href="/">
          <img src={logo} style={{width: "175px"}} alt="Logo"/>
        </a>
        <div style={{display:"flex", alignItems:"center"}}>
          <a className="nav-link d-lg-none" href={"/eng"} style={{marginRight:"10px"}}><img src={flag} width={"20"} alt=""/> EN</a>
          <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
          >

            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 text-center">
            <li className="nav-item dropdown">
              <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
              >
              Osiguranja
              </a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="/insurance">Obavezno osiguranje</a></li>
                <li><a className="dropdown-item" href="/kasko">Kasko osiguranje</a></li>
                <li><a className="dropdown-item" href="/dronInsurance">Osiguranje drona</a></li>
                <li><a className="dropdown-item" href="/registration">Registracija operatora</a></li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>
                <li><a className="dropdown-item" href="/law">Zakonska regulativa</a></li>
                <li><a className="dropdown-item" href="/categories">Kategorije letačkih operacija</a></li>
                <li><a className="dropdown-item" href="/training">Osposobljavanje udaljenog pilota</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/faq">FAQ</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/history">Povijest</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/blog">Aktualnosti</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={document} target={"_blank"}>Upitnik</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href={"/eng"}><img src={flag} width={"20"} alt=""/> EN</a>
            </li>
          </ul>
          <form className="d-flex" role="search">
            <button
                className="btn"
                type="submit"
                style={{backgroundColor: "transparent", color: "black"}} // Tamno plava boja
            >
              <a href={"/contact"} style={{textDecoration: "none", color: "black", backgroundColor: "transparent"}}>
                Kontaktirajte nas
              </a>
            </button>

          </form>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
