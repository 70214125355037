import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
  {
    title: "",
    content: "Kasko osiguranje drona pokriva štete nastale oštećenjem, uništenjem ili nestankom bespilotnog zrakoplova od sljedećih rizika: zrakoplovne nesreće (sudar, pad), pad ili udar nekog predmeta, požara, šteta na električnoj instalaciji, izravnog udara groma, eksplozije, oluje, tuče ili grada, krađe, zlonamjernih postupaka trećih osoba..."
  },
  {
    title: "Vrste dronova u kasko osiguranju",
    content: "Kod kasko osiguranja drona napravili smo dvije podjele: na rotore i krila.\n" +
        "Najzastupljenija kategorija dronova su oni koji koriste rotore, a iskustveno pad takvog sustava u pravilu znači i potpuno uništenje drona i opreme koju nosi.\n" +
        "Kod dronova koji koriste krila za stvaranje uzgona, u slučaju kvara na pogonu sustav se u pravilu ponaša kao jedrilica i štete na samom dronu i opremu su značajno manje.\n"
  },
  {
    title: "Vrste kasko osiguranja",
    content: "Postoje dvije opcije kasko osiguranja. Kod obje su pokriveni svi rizici iz ugovora o kasko osiguranju drona.\n" +
        "Puni kasko - svi rizici bez ograničenja\n" +
        "Djelomični kasko - svi rizici samo za vrijeme izvođenja letačke operacije\n"
  },
    {
    title: "Mogućnosti osiguranja drona i opreme",
    content: "Dronovi često dolaze s integriranom kamerom ili drugim uređajem i u tom slučaju osiguravamo cijeli sustav.\n" +
        "Dronovi kod kojih je moguće montirati dodatnu opremu koju nose, poput lidara, termalne, multispektralne ili fotogrametrijska kamere, moguće je osigurati samo dron, cijeli sustav ili pojedinu opremu ako dron ima policu kasko osiguranja proizvođača drona.\n"
  },
  {
    title: "Franšiza",
    content: "Odbitna franšiza kod kasko osiguranja drona iznosi 10% svote osiguranja, minimalno 200 €. Što znači da se u slučaju štete prvih 10% vrijednosti drona ili 200€ ne isplaćuje."
  }
];

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.contentWrapper}>
        <h1 className={styles.aboutSectionTitle}>Kasko osiguranje bespilotnog zrakoplova (drona)
        </h1>
        {contentBlocks.map((block, index) => (
            <ContentBlock key={index} title={block.title} content={block.content}/>
        ))}
      </div>
    </section>
  );
}

export default AboutSection;