import React from 'react';
import styles from './../../css/FAQSection.module.css';
import FAQItem from './FAQItem';

const faqData = [
  {
    question: "Tko mora ugovoriti policu osiguranja od odgovornosti operatora bespilotnog zrakoplova (drona)?",
    answer: "Ugovaranje police osiguranja od odgovornosti operatora bespilotnog zrakoplova (obvezno osiguranje drona) obvezno je za sve modele čiji je MTOM veći od 20 kg i sve bespilotne zrakoplove koji izvode letačke operacije. Izvođenjem letačke operacije smatraju se radovi iz zraka poput snimanja, mjerenja, nadzora i slično za privatne ili komercijalne svrhe."
  },
  {
    question: "Ugovara li policu osiguranja operator ili udaljeni pilot?",
    answer: "Operator je odgovoran za upotrebu drona i njegova je obveza ugovoriti policu u skladu s Uredbom (EZ) 785/2004"
  },
  {
    question: "Kolika je minimalna svota osiguranja?",
    answer: "Minimalna svota osiguranja proizlazi iz Uredbe (EZ) 785/2004 i iznosi 750.000 SDR-a"
  },
  {
    question: "Što je to SDR",
    answer: "SDR je međunarodna obračunska jedinica, a vrijednost joj je prosjek dnevne vrijednosti eura, funte, jena, juana i dolara. Trenutno je to cca. 909.000 €"
  },
  {
    question: "Moram li ishoditi policu obveznog osiguranja ako je moj dron opremljen kamerom?",
    answer: "Ne nužno! Obveza ishođenja obaveznog osiguranja ovisi o namjeni drona, a ne o njegovoj opremi. Ako je namjena drona izvođenje radova iz zraka, onda je polica obaveznog osiguranja obvezna. Izvođenjem radova iz zraka smatra se i fotografiranja i snimanje za privatne svrhe."
  },
  {
    question: "Što ako prilikom registracije operatora pogrešno izjavim namjenu svojih operacija?",
    answer: "Ukoliko ste prilikom registracije operatora izjavili da ćete se baviti isključivo sportsko rekreativnim letenjem, a utvrđeno je da ste se bavili radovima iz zraka, te time izbjegli ishođenje police obveznog osiguranja za taj je prekršaj zapriječena novčana kazna u iznosu od 2.652€ do 26.520€."
  },
  {
    question: "Trebam li za svaki dron ugovarati posebnu policu?",
    answer: "Da, svaku dron treba imati svoju policu osiguranja."
  },
  {
    question: "Što ako odlučim promijeniti teritorijalno pokriće police osiguranja ili namjenu korištenja?",
    answer: "U tom slučaju radi se aneks police i po potrebi doplata premije."
  },
  {
    question: "Ako je fizička ili pravna osoba registrirani operator u jednoj od članica EU mora li se registrirati ponovo i u Hrvatskoj?",
    answer: "Ne, proces registracije se vrši samo jednom u državi prebivališta za građane EU, odnosno u prvoj državi izvođenja operacija za državljane trećih zemalja."
  },
  {
    question: "Ako sam registrirani operator u drugoj državi, a nemam policu osiguranja, mogu li ju ugovoriti?",
    answer: "Da, policu mogu ugovoriti strani državljani, ali samo za područje Republike Hrvatske"
  },
  {
    question: "Je li na policu obveznog dron osiguranja moguće upisati dodatne osiguranike?",
    answer: "Da, moguće je upisati dodatne osiguranike na policu osiguranja od odgovornosti operatora bespilotnog zrakoplova (drona)"
  },
  {
    question: "U slučaju pada i uništenja drona imam li pravo na naknadu po polici obveznog osiguranja za dron?",
    answer: "Ne, policom obveznog osiguranja za dron, odnosno osiguranja od odgovornosti operatora bespilotnog zrakoplova (drona) pokrivene su štete učinjene trećim osobama zbog smrti, tjelesne ozljede, narušavanja zdravlja, uništenja ili oštećenja stvari."
  }
];

function FAQSection() {
  return (
    <section className={styles.faq}>
      <div className={styles.banner} />
      <div className={styles.container}>
        <h2 className={styles.title}>Često postavljana pitanja</h2>
        <div className={styles.faqList}>
          {faqData.map((item, index) => (
            <FAQItem key={index} question={item.question} answer={item.answer} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQSection;