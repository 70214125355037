import React from 'react';
import styles from './../../css/Banner.module.css';
import zakon from './../../img/Zakonska regulativa.png';

function Banner() {
  return (
    <section className={styles.banner}>
      <img src={zakon} alt="Banner Image" className={styles.bannerImage} />
    </section>
  );
}

export default Banner;