import React from 'react';
import styles from './../../css/ContentBlock.module.css';

function ContentBlock({ title, content }) {
  return (
    <div className={styles.contentBlock}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.divider} />
      <div className={styles.content}>
        {typeof content === 'string'
          ? content.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))
          : content // Directly render if content is not a string
        }
      </div>
    </div>
  );
}

export default ContentBlock;
