import React from 'react';
import styles from './../../css/HistoryItem.module.css';
import HistorySection from "./HistorySection";
import Footer from "../Footer";
import NavBar from "../Navbar/NavBar";

function HistoryPage() {
  return (
    <div>
        <NavBar/>
        <div className={styles.banner}>
            <h1>Povijest osiguranja drona u Hrvatskoj</h1>
        </div>
        <HistorySection/>
        <Footer/>
    </div>
  );
}

export default HistoryPage;