import React from "react";
import ServicesSection from "./ServicesSection";
import CallToAction from "./CallToAction";
import Footer from "./../Footer";
import styles from "./../../css/OtherInsurances.module.css";
import NavBar from "../Navbar/NavBar";
import ContactUs from "../ContactUs/ContactUs";

function OtherInsurancesMain() {
  return (
    <div className={styles.ostaleVrsteOsiguranja}>
      <NavBar />
      <main>
        <ServicesSection />
        <ContactUs />
      </main>
      <Footer />
    </div>
  );
}

export default OtherInsurancesMain;