import React from 'react';
import FAQSection from "./FAQSection";
import Footer from "../Footer";
import NavBar from "../Navbar/NavBar";

function MainComponent() {
  return (
    <main>
        <NavBar/>
      <FAQSection />
      <Footer />
    </main>
  );
}

export default MainComponent;