import React, { useState } from 'react';
import styles from './../../css/FAQSection.module.css';

function FAQItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.faqItem}>
      <div className={styles.questionWrapper} onClick={() => setIsOpen(!isOpen)}>
        <h3 className={styles.question}>{question}</h3>
        <button className={styles.toggleButton} aria-expanded={isOpen} aria-label={isOpen ? "Zatvori odgovor" : "Otvori odgovor"}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/dbb071e5d60b9b0f243dcf53559406b24499f9cdc34ed72acb79920c3d61c437?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" className={styles.toggleIcon} />
        </button>
      </div>
      {isOpen && <p className={styles.answer}>{answer}</p>}
    </div>
  );
}

export default FAQItem;