import React from 'react';
import styles from './../../css/ContactForm.module.css';

function ContactHeader() {
    return (
        <header className={styles.contactHeader}>
            <h1 className={styles.title}>Kontaktirajte nas</h1>
            <p className={styles.subtitle}>
                Za sve upite i nejasnoće oko osiguranja drona, registracije operatora i osposobljavanja udaljenih pilota
                slobodno nam se javite.
            </p>
        </header>
    );
}

export default ContactHeader;