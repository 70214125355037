import React from 'react';
import styles from './../../css/ContentBlock.module.css';

function ContentBlock({ title, content }) {
  return (
    <div className={styles.contentBlock}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.divider} />
      <p className={styles.content}>
        {content.split('\n').map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  );
}

export default ContentBlock;
