import React from 'react';
import styles from './../../css/NewsPage.module.css';
import {Link} from "react-router-dom";

const FeaturedPost = () => {
  return (
<Link to="/droneDaysArticle" style={{textDecoration:"none", color:"white", width:"100%"}}>
          <article className={styles.featuredPost}>

              <div className={styles.content}>
                  <h2 className={styles.heading}>
                      DroneDays 2024
                  </h2>
                  <p className={styles.date}>Listopad, 2024</p>
              </div>

          </article>
      </Link>

  );
};

export default FeaturedPost;