import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
    {
        title: "",
        content: (
            <>
                Koje radnje je potrebno poduzeti prije leta?<br />
                Operator ili udaljeni pilot?<br />
                Uredbe i propisi
            </>
        ),
    },
    {
        title: "Osiguranje od odgovornosti operatora bespilotnog zrakoplova",
        content: (
            <>
                Polica osiguranja drona prvi je korak koji je potrebno napraviti prilikom registracije operatora bespilotnog zrakoplova kod Hrvatske agencije za civilno zrakoplovstvo.
                Registrirati se trebaju svi operatori čiji dron ima veću masu pri polijetanju od 250 g i opremljeni su uređajem za prikupljanje osobnih podataka (kamera, mikrofon i sl.).
                Policu osiguranja ne moraju ugovoriti svi operatori, već samo oni koji će s dronom izvoditi operacije iz zraka poput snimanja, fotografiranja, oglašavanja, nadzora i slično,
                komercijalno ili ne, za privatne ili javne potrebe.
                Policu osiguranja ugovara operator (najčešće vlasnik drona), a ne udaljeni pilot.
                Operator je fizička ili pravna osoba odgovorna za upotrebu drona, a udaljeni pilot je osoba koja leti s dronom.
            </>
        ),
    },
    {
        title: "Udaljeni pilot",
        content: (
            <>
                Udaljeni pilot je fizička osoba koja upravlja dronom. Udaljeni pilot se ne registrira u sustavu registracije HACZ, već obavlja potrebno osposobljavanje i provjeru sukladno primjenjivim propisima.
            </>
        ),
    },
    {
        title: "Prijava leta",
        content: (
            <>
                Prijava leta započinje registracijom na AMC portalu i potvrdom maila, nakon čega udaljeni pilot šalje potvrdu o operaterstvu i suglasnost operatora da udaljeni pilot smije upravljati dronom registriranog operatora
                (ako udaljeni pilot nije operator ili osoba ovlaštena za zastupanje operatora).
                Nakon provjere podataka udaljenom pilotu se dodjeljuje korisničko ime i lozinka.<br />
                Za informiranje o zemljopisnim područjima, pregledu područja gdje smije, a gdje ne smije letjeti te rezervaciju zračnog prostora koristi se aplikacija AMC Portal Mobile.
                Ovisno o području planirane letačke operacije svoje letove moraju prijavljivati svi udaljeni piloti, bez obzira na masu bespilotnog zrakoplova kojim izvode operacije.
                Uspostava strukture zračnog prostora ishodi se u:<br />
                redovitom postupku (putem dostave obrasca zahtjeva 7 dana prije namjeravanog početka operacija) i<br />
                u automatskom postupku (putem aplikacije AMC Mobile).<br />
                Način na koji će operator i/ili udaljeni pilot uspostaviti strukturu zračnog prostora za svoje operacije ovisi o vrsti zračnog prostora u kojem će se operacije izvoditi.
                Više o tome na sljedećoj stranici{" "}
                <a href="https://amc.crocontrol.hr/" target="_blank" rel="noopener noreferrer">https://amc.crocontrol.hr/</a>.
            </>
        ),
    },
    {
        title: "Uredba (EU) 2019/945 i Uredba (EU) 2019/947",
        content: (
            <>
                Da bi se tijekom operacija bespilotnim zrakoplovima zajamčila sigurnost ljudi na tlu i drugim korisnicima zračnog prostora potrebno je utvrditi zahtjeve povezane s plovidbenošću, organizacijama, osobama uključenima u rad bespilotnih zrakoplova i operacijama bespilotnih zrakoplova.<br />
                Prvog srpnja 2019. godine na snagu su stupile dvije uredbe Europske komisije koje uređuju područje bespilotnih zrakoplova, a to su:<br />
                Uredba (EU) 2019/945 o sustavima bespilotnih zrakoplova i o operatorima sustava bespilotnih zrakoplova iz trećih zemalja<br />
                Uredba (EU) 2019/947 o pravilima i postupcima za rad bespilotnih zrakoplova.
            </>
        ),
    },
];


function AboutSection() {
    return (
        <section className={styles.aboutSection}>
            <div className={styles.contentWrapper}>
                <h1 className={styles.aboutSectionTitle}>Zakonska regulativa
                </h1>
                {contentBlocks.map((block, index) => (
                    <ContentBlock key={index} title={block.title} content={block.content}/>
                ))}
            </div>
        </section>
    );
}

export default AboutSection;