import React from 'react';
import styles from './../../css/NewsPage.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.pageInfo}>
        <h1>Aktualnosti</h1>
        <nav aria-label="Breadcrumb" className={styles.breadcrumb}>
          {/* Breadcrumb content here */}
        </nav>
      </div>
    </header>
  );
};

export default Header;