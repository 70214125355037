import React from 'react';
import styles from './../../css/ContactForm.module.css';

function ContactForm() {
  return (
    <section className={styles.contactSection}>
      <div className={styles.formWrapper}>
        <h2 className={styles.formTitle}>Stojimo Vam na usluzi!</h2>
        <p className={styles.formDescription}>
          Za sva dodatna pitanja i nedoumice, slobodno nas kontaktirajte. Naš stručni tim je tu da vam pruži sve potrebne informacije i vodi vas kroz proces. S nestrpljenjem čekamo Vaš poziv!
        </p>
        <address className={styles.contactInfo}>
          <p>Semel Pro Semper d.o.o.</p>
          <p>Email: <a href="mailto:hrvoje.sremic@sps.hr">hrvoje.sremic@sps.hr</a></p>
          <p>Tel: <a href="tel:+385915698050">091/5698050</a></p>
        </address>
      </div>
      <div className={styles.imageWrapper}>
        <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d4bc61124350b825a99fafddf46dec86cf48cd65334c86b0e2254c968b0f3c7?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="Contact illustration" className={styles.contactImage} />
      </div>
    </section>
  );
}

export default ContactForm;