import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';
import documetn from './../../document/Upitnik za osiguranje bespilotnog zrakoplova.docx'

const contentBlocks = [
  {
    title: "",
    content: "Ugovorite online policu obveznog osiguranja za dron.\n" +
      "Dron osiguranje na dohvat ruke, kasko ili osiguranje od odgovornosti operatora bespilotnog zrakoplova."
  },
  {
    title: "",
    content: "Ugovaranje police osiguranja od odgovornosti operatora bespilotnog zrakoplova (osiguranje drona) obvezno je za sve modele čiji je MTOM veći od 20 kg i sve bespilotne zrakoplove koji izvode letačke operacije, kao na primjer DJI Mini 4 Pro, DJI Air 3, DJI Mavic 3. Izvođenjem letačke operacije smatraju se radovi iz zraka poput snimanja, mjerenja, nadzora i slično za privatne ili komercijalne svrhe.\n" +
      "Policu osiguranja drona moguće je ugovoriti s teritorijalnim pokrićem Hrvatske, Europe ili cijelog svijeta, a premija ovisi o namjeni korištenja: privatno letenje (sport i hobi) ili komercijalno letenje (objava materijala, znanstveni letovi, istraživački letovi, nadzor, zaštita i spašavanje, poljoprivreda, industrija i sl.)."
  },
  {
    title: "",
    content: <>
      {"Policu obveznog osiguranja bespilotnog zrakoplova (drona) i kasko osiguranje drona moguće je ugovoriti ispunjavanjem "}
      <a href={document} target="_blank" rel="noopener noreferrer">UPITNIKa</a>
      {" i slanjem na mail adresu "}
      <a href="mailto:hrvoje.sremic@sps.hr">hrvoje.sremic@sps.hr</a>
      {". Ili putem intuitivnog "}
      <a href="/form">IZRAČUNa</a>
      {" sa preddefiniranim podacima. U istom izračunu moguće je saznati cijenu kasko osiguranja.\n" +
      "Ugovaranje police obveznog osiguranja drona uvjet je za ugovaranje kasko osiguranja.\n" +
      "Ukoliko se ugovara i kasko osiguranje drona potrebno je dostaviti račun te par slika letjelice i opreme koja se osigurava, a na jednoj slici mora biti vidljiv serijski broj."}
    </>
  },
  {
    title: "",
    content: "Osiguranje drona, dron osiguranje ili osiguranje od odgovornosti operatora bespilotnog zrakoplova ne ugovara udaljeni pilot, već je to obveza operatora bespilotnog zrakoplova. Za svaki dron u vlasništvu operatora potrebno je ugovoriti zasebnu policu."
  },
];


function AboutSection() {
  return (
      <section className={styles.aboutSection}>

          <div className={styles.contentWrapper}>
              <h1 className={styles.aboutSectionTitle}>Osiguranje od odgovornosti operatora bespilotnog zrakoplova (drona)
              </h1>
              {contentBlocks.map((block, index) => (
                  <ContentBlock key={index} title={block.title} content={block.content}/>
              ))}
          </div>
      </section>
  );
}

export default AboutSection;