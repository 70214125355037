import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
    {
        title: "",
        content: "Polaganje ispita za udaljene pilote provodi se neovisno o registraciji operatora (pilot ne mora posjedovati dron) i obvezan je za sve koji će izvoditi letačke operacije s dronom čiji je MTOM veći od 250 g ili i za dronove čiji je MTOM manji od 250 g, ali imaju C1 oznaku, kao npr. DJI Mini 4 Pro."
    },
    {
        title: "Tko polaže ispit",
        content: "Provedbena Uredba Komisije (EU) 2019/947 od 24. svibnja 2019. o pravilima i postupcima za rad bespilotnih zrakoplova, propisuje da će udaljeni piloti prije izvođenja operacija bespilotnim zrakoplovima u „otvorenoj kategoriji“ morati položiti teorijske ispite. Otvorena kategorija UAS operacija podijeljena je na tri potkategorije: A1, A2 i A3, na temelju radnih ograničenja, zahtjeva koji se odnose na udaljene pilote i tehničkih zahtjeva za UAS-e."
    },
    {
        title: "U praksi",
        content: (
            <>
                {" Polaganje ispita za udaljenog pilota potkategorije A1/A3 omogućeno je na web stranicama HACZ (CCAA) ("}
                <a href="https://www.ccaa.hr/osposobljavanje-za-udaljene-pilote-28241" target="_blank" rel="noopener noreferrer">
                    https://www.ccaa.hr/osposobljavanje-za-udaljene-pilote-28241
                </a>
                {" i slanjem na mail adresu "}
                <a href="mailto:hrvoje.sremic@sps.hr">hrvoje.sremic@sps.hr</a>
                {"), dok se polaganje za potkategoriju A2 provodi prema rasporedu, objavljenom na web stranicama HACZ, na nekoliko lokacija u Republici Hrvatskoj. Uredba ne propisuje obvezu polaganja ispita za dronove čiji je MTOM manji od 250 g, ali je to preporučljivo radi upoznavanja s pravilima letenja."}
            </>
        )
    },
    {
    title: "",
    content: (
      <>
        Detaljne informacije o osposobljavanju udaljenih pilota pronađite u dokumentu UAS-UPUTE-005 na sljedećoj stranici:
        <a href="https://www.ccaa.hr/obrasci-i-publikacije-42179" target="_blank" rel="noopener noreferrer">
          https://www.ccaa.hr/obrasci-i-publikacije-42179
        </a>
      </>
    )
  },
];

function AboutSection() {
    return (
        <section className={styles.aboutSection}>
            <div className={styles.contentWrapper}>
                <h1 className={styles.aboutSectionTitle}>Osposobljavanje udaljenog pilota</h1>
                {contentBlocks.map((block, index) => (
                    <ContentBlock key={index} title={block.title} content={block.content}/>
                ))}
            </div>
        </section>
    );
}

export default AboutSection;

