import React from 'react';
import styles from './../../css/Registration.module.css';
import ServiceStep from './ServiceStep';
import polica from "./../../img/Ugovaranje police.png"
import registracija from "./../../img/Registracija.png"
import ispit from "./../../img/Polaganje ispita.png"
import prijava from "./../../img/Prijava leta.png"

function ServiceSteps() {
  const steps = [
    {
      title: "Ugovaranje police",
      description: "Ugovaranje police osiguranja od odgovornosti operatora bespilotnog zrakoplova (drona).",
      image: polica,
      link: { text: "Izračun", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/af8a37944f631dff9dfacf8fc6f54c67a10d9aee51e74a29d8b06860790ec73d?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc", url: "/form" }
    },
    {
      title: "Registracija operatora bespilotnog zrakoplova (drona)",
      description: "Registracija operatora na stranicama CCAA. Registarski broj operatora mora se zalijepiti na dron, bez znakova iza minusa.",
      image: registracija,
      link: { text: "Registracija operatora", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/dc8e48a73e52bf4e1a533f04a33d46783c6a948c33025293d92bd9516c08cf09?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc", url: "https://drone.ccaa.hr/user2/index.php" }
    },
    {
      title: "Polaganje ispita",
      description: ispit,
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/f6a76f8a2b4bd7ed7bdaf065ae90f2b2d2d2e397c2b4916b695a96da0564c512?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
      link: { text: "Polaganje ispita", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/59a27030ba19c2bc7ebb9529037008fccb5fed3f3559d53c4210f8941bdd421b?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc", url: "https://www.ccaa.hr/osposobljavanje-za-udaljene-pilote-28241" }
    },
    {
      title: "Prijava leta",
      description: "Registracija pilota i prijava leta na AMC portalu.",
      image: prijava,
      link: { text: "Prijava leta", icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/40d3fd5831f88a00beaaa66b1a7c52348d0f5a99b9f0ed4ac63e8bdb76fe17e9?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc", url: "https://amc.crocontrol.hr/" }
    }
  ];

  return (
    <section className={styles.services}>
      <header className={styles.servicesHeader}>
        <h2 className={styles.servicesTitle}>Koraci u registraciji</h2>
        <div className={styles.serviceDivider} />
        <p className={styles.servicesDescription}>
          Osiguranje drona, registracija operatora, osposobljavanje udaljenog pilota i prijava leta procedura je koju je potrebno odraditi za legalno letenje.
        </p>
      </header>
      <div className={styles.serviceSteps}>
        {steps.map((step, index) => (
          <ServiceStep key={index} step={index + 1} {...step} />
        ))}
      </div>
    </section>
  );
}

export default ServiceSteps;
