import React from 'react';
import styles from './../../css/BlogContent.module.css';
import BlogContent from './BlogContent';
import NavBar from "../Navbar/NavBar";
import Footer from "../Footer";


export default function BlogLayout() {
  return (
    <div className={styles.mainWrapper}>
      <NavBar/>
      <BlogContent />
      <Footer />
    </div>
  );
}