import React from 'react';
import Banner from './Banner';
import AboutSection from './AboutSection';
import ContactSection from './ContactSection';
import styles from './../../css/InsuranceMainPage.css';
import NavBar from "../Navbar/NavBar";
import Footer from "../Footer";
import ContactUs from "../ContactUs/ContactUs";

function MainPage() {
  return (
    <div className={styles.mainPage}>
      <NavBar />
      <main>
        <Banner />
        <AboutSection />
        <ContactUs />
      </main>
      <Footer />
    </div>
  );
}

export default MainPage;