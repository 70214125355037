import React from 'react';
import styles from './../../css/Banner.module.css';
import eng from './../../img/English.png'

function Banner() {
  return (
    <section className={styles.banner}>
      <img src={eng} alt="Banner Image" className={styles.bannerImage} />
    </section>
  );
}

export default Banner;