import React from 'react';
import styles from './../css/Registration.module.css';

function Footer() {
  const companyLinks = [
    { name: "Registracija", href: "/registration" },
    { name: "O nama", href: "/" },
    { name: "Recenzije", href: "/reviews" },
    { name: "Klijenti", href: "/clients" }
  ];

  const serviceLinks = [
    { name: "Osiguranje odgovornosti operatora bespilotnog zrakolova", href: "/insurance" },
    { name: "Kasko osiguranje", href: "/kasko" },
    { name: "FAQ", href: "/faq" },
    { name: "Ostale vrste osiguranja", href: "/otherInsurances" }
  ];

  const otherLinks = [
    { name: "Povijest", href: "/history" },
    { name: "Aktualnosti", href: "/blog" },
    { name: "Zakonske regulative", href: "/law" }
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.footerLogo}>
          <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a4b7aff467f1a796e1337984622eb2894303b07baf4820873bfd6541904b5c0?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="Company logo" className={styles.footerLogoImage} />
          <p className={styles.footerLegalText}>
            Trgovačko društvo Semel pro Semper d.o.o. upisano je u Sudski registar Trgovačkog suda u Zagrebu pod matičnim brojem subjekta 080722235
            <br />
            OIB: 34497973088
            <br/>
            Temeljni kapital 100.000,00 kuna / 13.272,25 eura - uplaćen u cjelosti
            <br />
            Hrvatska poštanska banka d.d.(OIB: 87939104217), Jurišićeva 4, 10 000 Zagreb
            <br />
            IBAN: HR6223900011101320944
            <br />
            Članovi društva: Hrvoje Sremić i Petar Rebernišak
          </p>
        </div>
        <div className={styles.footerLinks}>
          <div className={styles.footerLinkColumn}>
            <h3 className={styles.footerLinkTitle}>Tvrtka</h3>
            <ul className={styles.footerLinkList}>
              {companyLinks.map((link, index) => (
                <li key={index}><a href={link.href}>{link.name}</a></li>
              ))}
            </ul>
          </div>
          <div className={styles.footerLinkColumn}>
            <h3 className={styles.footerLinkTitle}>Linkovi</h3>
            <ul className={styles.footerLinkList}>
              {serviceLinks.map((link, index) => (
                <li key={index}><a href={link.href}>{link.name}</a></li>
              ))}
            </ul>
          </div>
          <div className={styles.footerLinkColumn}>
            <h3 className={styles.footerLinkTitle}>Ostalo</h3>
            <ul className={styles.footerLinkList}>
              {otherLinks.map((link, index) => (
                <li key={index}><a href={link.href}>{link.name}</a></li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <p className={styles.footerCopyright}>
          © Sva prava pridržana - designed by IT Consulting Services d.o.o.
        </p>
        <div className={styles.footerSocial}>
          <a href="#" aria-label="Facebook"><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d33bc58a07e1238cb0b240c110ff556f5e8c9231309a859819da0bc237a6957?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" /></a>
          <a href="https://www.facebook.com/profile.php?id=61556377334776" target={"_blank"} aria-label="Twitter"><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b4deb93c219dedf8f8777d13e13447ddb773d63e3b06852ff43ed7c47fa7904?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" /></a>
          <a href="#" aria-label="LinkedIn"><img src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e1ea47cdde649f50b9a2461292e8c8622b00e7b0f52c9b5aa0338de9e0932eb?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" /></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
