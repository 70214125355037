import React from 'react';
import styles from './../../css/BlogContent.module.css';
import image01 from "./../../img/20241009_zatvaranje_d_vedran_radovanovic_06.jpg";
import image02 from "./../../img/20241009_zatvaranje_d_vedran_radovanovic_09.jpg";
import image03 from "./../../img/20241008_drone_days_predavanje_d_andro_anic_milic_02.jpg";
import image04 from "./../../img/bronse sponzors.png"
import image05 from "./../../img/droneDays.png"

function BlogContent({
                         title = "DroneDays 2024",
                         date = "Listopad, 2024",
                         mainImage = image03,
                         content = {
                             sections: [
                                 {
                                     title: "",
                                     content: "Četvrto izdanje DroneDaysa, održano je 8. i 9. listopada 2024. godine u zgradi Sveučilišta u Zagrebu u organizaciji Laboratorija za robotiku i inteligentne sustave upravljanja (LARICS Lab) i Fakulteta elektrotehnike i računarstva (FER). Okupilo se 250 domaćih i stranih sudionika, 8 izlagača, održano je 15 predavanja i 2 panela.",
                                     image: image01
                                 },
                                 {
                                     title: "",
                                     content: "Kao neizostavni dio izvođenja letačkih operacija osiguranje drona je uvijek aktualna tema pa smo kao i prošle godine podržali skup sponzorstvom.",
                                     image: image02
                                 },
                                 {
                                     title: "",
                                     content: "https://dronedays.fer.hr/en/homepage-2024-2/"
                                 },
                                 {
                                     title: "",
                                     content: "",
                                     image: image04
                                 },
                                 {
                                     title: "",
                                     content: "",
                                     image: image05
                                 },
                             ]
                         }
                     }) {
    return (
        <main className={styles.content}>
            <div className={styles.mainContent}>
                <article className={styles.blogArticle}>
                    <header className={styles.blogInfo}>
                        <h1 className={styles.heading}>{title}</h1>
                        <time className={styles.shortInfo}>{date}</time>
                    </header>
                    <img
                        src={mainImage}
                        alt="Dron u letu"
                        className={styles.mainImage}
                        loading="lazy"
                    />
                    <p className={styles.introduction}>
                        {content.introduction}
                    </p>
                    {content.sections.map((section, index) => (
                        <section key={index} className={styles.section}>
                            <h2 className={styles.sectionTitle}>{section.title}</h2>
                            {section.content.startsWith("http") ? (
                                <a
                                    href={section.content}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.sectionLink}
                                >
                                    https://dronedays.fer.hr/en/homepage-2024-2/
                                </a>
                            ) : (
                                <p className={styles.sectionContent}>{section.content}</p>
                            )}
                            {section.image && (
                                <img
                                    src={section.image}
                                    alt={`Ilustracija za sekciju ${section.title}`}
                                    className={styles.sectionImage}
                                    loading="lazy"
                                />
                            )}
                        </section>
                    ))}
                </article>
            </div>
        </main>
    );
}

export default BlogContent;
