import React from 'react';
import ContactHeader from './ContactHeader';
import ContactForm from './ContactForm';
import styles from './../../css/ContactForm.module.css';
import NavBar from "../Navbar/NavBar";
import Footer from "../Footer";

function ContactPage() {
  return (
    <div className={styles.contactPage}>
      <NavBar/>
      <ContactHeader />
      <ContactForm />
      <Footer />
    </div>
  );
}

export default ContactPage;