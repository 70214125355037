import React from "react";
import styles from "./../../css/ServicesSection.module.css";

function ServicesSection() {
  const serviceImages = [
    "https://cdn.builder.io/api/v1/image/assets/TEMP/7f461b9efe0a4e3a800867169d778d298736445b53b991f934d516d536ed5b0d?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    "https://cdn.builder.io/api/v1/image/assets/TEMP/c6fad0daac061737a64e83423594dde5130f1a8cb8fbb4de8eb7dd31ba27eb8a?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    "https://cdn.builder.io/api/v1/image/assets/TEMP/63fe504d6729b2f520a594ef6a66b4d1381659aa6cd52b1448fef2a7e7bffef8?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    "https://cdn.builder.io/api/v1/image/assets/TEMP/58cfc26f3c1b8dd51753dfdde6e0acd7b4608f1765339ecb95acdefc058ab715?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    "https://cdn.builder.io/api/v1/image/assets/TEMP/398d932ca4bf6a994213129b8ab170ce22980d56f75349759e181e21479100ef?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
    "https://cdn.builder.io/api/v1/image/assets/TEMP/e8cfe7881b29ff48cbcf8f6990a7ed7e2b820465b357d39b1be4eaf1ea51ceb0?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc",
  ];

  return (
    <section className={styles.servicesSection}>
      <h2 className={styles.sectionTitle}>Ostale vrste osiguranja</h2>
      <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/034629a0cbb5ca1f2cbc31969c2b4d5a7165132ba8e163c010fb67633a9726c8?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" alt="" className={styles.decorativeIcon} />
      <div className={styles.serviceGrid}>
        {serviceImages.map((src, index) => (
          <img key={index} src={src} alt={`Service ${index + 1}`} className={styles.serviceImage} />
        ))}
      </div>
    </section>
  );
}

export default ServicesSection;