import React from 'react';
import styles from './../../css/Banner.module.css';
import dronInsurance from './../../img/Dron insurance.png'

function Banner() {
  return (
    <section className={styles.banner}>
      <img src={dronInsurance} alt="Banner Image" className={styles.bannerImage} />
    </section>
  );
}

export default Banner;