import React from 'react';
import styles from './../../css/InsuranceCard.module.css';

const InsuranceCard = ({ title, description, link }) => {
  return (
    <article className={styles.card}>
      <header className={styles.cardHeader}>
        <div className={styles.indicator} />
        <h2 className={styles.title}>{title}</h2>
      </header>
      <div className={styles.cardContent}>
        <p className={styles.description}>{description}</p>
        <footer className={styles.cardFooter}>
          <a href={link} className={styles.readMore}>PROČITAJ VIŠE</a> {/* Koristi link */}
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0206ebf7172f5c5ef3b04c992ca9aabe786e70c6648265fb57883a21e3403d91?placeholderIfAbsent=true&apiKey=7ecb829facc1490fb94a0c8f410b93bc" className={styles.icon} alt="" />
        </footer>
      </div>
    </article>
  );
};

export default InsuranceCard;
