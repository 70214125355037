import React from 'react';
import styles from './../../css/Banner.module.css';
import kategorije from './../../img/Kategorije.png'

function Banner() {
  return (
    <section className={styles.banner}>
      <img src={kategorije} alt="Banner Image" className={styles.bannerImage} />
    </section>
  );
}

export default Banner;