import React from 'react';
import styles from './../../css/ContentBlock.module.css';

function ContentBlock({ title, content, otherTitle }) {
  return (
    <div className={styles.contentBlock}>
      <h2 className={styles.title}>{title}</h2>
      <h2 className={styles.otherTitle}>{otherTitle}</h2>
      <div className={styles.divider} />
      {content.split('\n').map((line, index) => (
        <p key={index} className={styles.content}>{line}</p>
      ))}
    </div>
  );
}

export default ContentBlock;
