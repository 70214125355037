import React from 'react';
import styles from './../../css/AboutSection.module.css';
import ContentBlock from './ContentBlock';

const contentBlocks = [
  {
    title: "",
    content: "Otvorena, posebna i certificirana kategorija.\n" +
        "Koji dron leti u kojoj kategoriji?\n"
  },
  {
    title: "Otvorena kategorija",
    content: "Za najširu zainteresiranu zajednicu: fotografi, geodeti, modelari…\n" +
        "MTOM (najveća dopuštena masa pri polijetanju) drona je do 25 kg.\n" +
        "Visina leta je do 120 metar iznad terena.\n" +
        "Let se izvodi na sigurnoj udaljenosti od neuključenih osoba.\n" +
        "Dron je uvijek u vidnom polju.\n" +
        "Ne izbacuju se stvari iz drona niti se prevozi opasna roba.\n" +
        "\n" +
        "Ovisno o klasifikaciji dronova izvode se letačke operacije u potkategorijama Otvorene kategorije\n" +
        "Bitno je uočiti podjelu na dronove koji imaju C oznake i one koji ih nemaju.\n" +
        "Popis dronova s C oznakama svakodnevno se ažurira.\n" +
        "Dronovi bez C oznake:\n" +
        "Do 250 g (Mavic Mini, Mini 2, Mini 3 Pro i sl.) i dalje se leti kao do sada u kategoriji A1. Isto vrijedi i za samogradnju do 250 g.\n" +
        "Od 250 g do 25 kg  (Air 2, Mavic 2 Pro, Mavic 3 Pro, Phantomi, Inspire 2, Matrice 300. Matrice 600) mogu letjeti u kategoriji A3.\n" +
        "Dronovi s C oznakom:\n" +
        "Do 900 g (Mini 4 Pro, Air 2S – ako se zatraži C oznaka od proizvođača, Air 3, Mavic 3 Classic i Cine) lete u A1 kategoriji.\n" +
        "Od 900 g do 4 kg (Mavic 3 Pro, Matrice M30) lete u A2 kategoriji.\n"
  },
  {
    title: "Posebna kategorija",
    content: "Operacije koje izlaze iz okvira otvorene kategorije.\n" +
        "Dronovi čiji je MTOM veći od 25 kg.\n" +
        "BVLOS operacije – operacije van vidnog polja udaljenog pilota.\n" +
        "Operacije iznad 120 m\n" +
        "Operacije u skladu sa standardnim scenarijima\n" +
        "Operacije u skladu s prethodno izrađenim procjenama rizika (PDRA)\n" +
        "Operacije u skladu s procjenom rizika za operacije u posebnoj kategoriji (SORA)\n" +
        "Operacije u skladu s Jednostavnom svjedodžbom operatora sustava bespilotnog zrakoplova (LUC)\n" +
        "\n"
  },
  {
    title: "Certificirana kategorija",
    content: "\n" +
        "Certificirani zrakoplovi, licencirani piloti, operacije u okruženju u kojem leti i zrakoplovstvo s posadom.\n" +
        "Letenje iznad skupina ljudi.\n" +
        "Prijevoz opasnih roba.\n" +
        "Prijevoz ljudi...\n" +
        "\n"
  },
];

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.contentWrapper}>
        <h1 className={styles.aboutSectionTitle}>Kategorije letačkih operacija
        </h1>
        {contentBlocks.map((block, index) => (
            <ContentBlock key={index} title={block.title} content={block.content}/>
        ))}
      </div>
    </section>
  );
}

export default AboutSection;